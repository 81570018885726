import styled from 'styled-components'
import Scrollbars from "react-custom-scrollbars";

export const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
`

export const Form = styled.form`
    background-color: #1B1B1B;
    height: 40px;
    border-radius: 7px;
    padding: 6px 9px 6px 12px;
    margin: 16px;
    min-height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    min-width: calc(100% - 32px);
    >svg {
        margin: 0 5px;
    }
    svg {
        color: inherit;
        height: .85rem;
    }
    .input_text {
        width: calc(100% - 40px);
        textarea{
            color: #FFFFFF;
            &:first-child{
                padding-right: 10px;
            }
        }
        &::before, &::after{
            content: unset;
        }
    }
    button {
        min-width: 0;
        padding: 11px 10px;
    }
`
export const Input = styled.form`
   background-color: transparent;
   color: #989898; 
`
export const MessageList = styled.ul`
    list-style: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 14px;
`
export const MessageHeader = styled.div`
   color: #AD3EDB;
   padding: 5px 3px;
   font-size: 10px;
   line-height: 12px;
   border-bottom: 1px solid #989898;
   span {
      margin-left: 16px;
      color: #FFFFFF;
   }
`
export const MessageContent = styled.div`
   color: #989898;
   padding: 5px 3px;
   font-size: 12px;
   line-height: 15px;
`
export const MessageItem = styled.li`
    background-color: #1B1B1B;
    display: flex;
    flex-direction: column;
    padding: 8px 6px;
    max-width: 90%;
    width: fit-content;
    border-radius: 5px 5px 0 5px;
    &:not(.client){
        align-self: flex-end;
    }
    &.client {
        border-radius: 5px 5px 5px 0;
        ${MessageHeader}{
            color: #EC3EA2;
        }
    }
`
export const InputFile = styled.input`
    display: none;
`
export const Scrollbar = styled(Scrollbars)`
    height: 100%;
    > div {
        &:last-child{
            &:not(.bar) {
                width: 7px!important;
                right: 4px!important;
            }
            &:nth-child(2) {
                display: none;
            }
            > div {
                background-color: ${props => props.theme.secondary}!important;
                opacity: .8;
            }
        }
    }`