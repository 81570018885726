import styled from 'styled-components'

export const Container = styled.div`
    color: #989898;
    display: ${({open}) => open ? `flex` : `none`};
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: #121212;
    z-index: 3;
    @media (max-width: 768px) {
        position: absolute;
    }
    @media (min-width: 768px) {
        width: 25%;
        min-width: 320px;
    }
`