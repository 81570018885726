import axios from 'axios'
import io from 'socket.io-client'
import urlGeneration from './urlGeneration'
const instanceVideo = axios.create({
    baseURL: `${urlGeneration()}/api/v1`,
    headers: {'Content-Type': 'application/json'}
});


export const socket = io.connect(`${urlGeneration()}`);

export const getSession = async (x) => {
    try {
        const { data } = await instanceVideo.get(`/session_tokbox/${x}`)
        return data
    } catch (err) {
        return err.response.data
    }
};
export const getChat = async (x) => {
    try {
        const { data } = await instanceVideo.get(`/chat/${x}`)
        return data
    } catch (err) {
        return err.response.data
    }
};

export const sendMessage = async (x) => {
    try {
        const { data } = await instanceVideo.post(`/chat/inbound/`, x)
        return data
    } catch (err) {
        return err.response.data
    }
};