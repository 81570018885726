import React,{useContext} from "react";
import * as S from './style'
import VideoContext from '../../context'
import Chat from './chat'
export default  (props) => {
    // State
    const {openDrawer} = useContext(VideoContext);

    // Effects

    // Business

    // Render
    return (<S.Container open={openDrawer}>
        <Chat/>
    </S.Container>)
}