import React from "react";
import Video from './component/video'
import ThemeProvider from 'component/theme'

export default function App() {
    // State

    // Effects

    // Business

    // Render
    return (<ThemeProvider>
        <Video />
    </ThemeProvider>)
}