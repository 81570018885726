import styled, {keyframes} from 'styled-components'

const rotate = keyframes`
    to {transform: rotate(0)}
    from {transform: rotate(360deg)}
`
export const Container = styled.div`
    color: red;
    width: 100%;
    height: 100%;
`

export const Closed = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #212121;
    color: #fafafa;
    .powered {
        position: absolute;
        bottom: 0;
        display: flex;
        color: #fff;
        font-size: 14px;
        padding: 32px;
        flex-direction: column;
        align-items: center;
        p {
            margin-bottom: 5px;
        }
    }
`
export const Load = styled.div`
    position: absolute;
    background-color: #212121;
    z-index: 100;
    width: 100%;
    height: 100%;
    p {
        position: absolute;
        color: #fff;
        width: 300px;
        text-align: center;
        top: calc(50% + 15px);
        left: calc(50% - 150px);
    }
    &:after {
        content: ' ';
        width: 40px;
        animation: infinite ${rotate} 1s linear;
        height: 40px;
        border: 3px solid #FFF;
        border-right-color: transparent;
        border-radius: 50%;
        left: calc(50% - 20px);
        top: calc(50% - 40px);
        position: absolute;
    }
`

export const Body = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100% - 4.5rem);
`