import React from "react";
import {ThemeProvider} from "styled-components";
import useTheme from './hooks'
import {GlobalStyle} from "./style";
import DarkTheme from 'assets/theme/dark'
import LightTheme from 'assets/theme/light'


export default ({children, props}) => {
    const [ theme ] = useTheme()
    return <ThemeProvider theme={theme === 'dark' ? DarkTheme : LightTheme}>
        <GlobalStyle/>
        {children}
    </ThemeProvider>
}