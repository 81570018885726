import styled from 'styled-components'

export const Container = styled.div`
    background-color: ${({theme}) => theme.accentBg};
    height: 4.5rem;
    font-size: 1.5rem;
    color: ${({theme}) => theme.text};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px 26px 32px;
    
    button {
        border: 0;
        background-color: transparent;
        color: inherit;
        width: 1.75rem;
        outline: none;
        height: 1.75rem;
        padding: .25rem;
        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
`