import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    width: 100%;
    background-color: #212121;
    color: #fff;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
        margin-right: 32px;
    }
    .preview {
        width: 90%;
        height: 50%;
        margin-bottom: 32px;
        @media (min-width: 768px) {
            width: 50%;
            height: 50%;
            margin-bottom: 0;
        }
        align-items: flex-end;
        justify-content: center;
        display: flex;
        position: relative;
        video {
            width: 100%;
            height: 100%;
        }
        > div {
            display: flex;
            position: absolute;
            justify-content: center;
            bottom: 16px;
        }
    } 
    .welcome {
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
            margin: 0 0 22px;
        }
        >button {
            border: 1px solid #fff;
            padding: 8px 33px;
            border-radius: 25px;
            color: #fff;
            outline: 0;
            
            cursor: pointer;
            &:not(:disabled) {
                background-color: transparent;
            }
        }
    }
    .powered {
        position: absolute;
        left: calc(50% - (182px/2));
        bottom: 0;
        display: flex;
        color: #fff;
        font-size: 14px;
        padding: 32px;
        flex-direction: column;
        align-items: center;
        p {
            margin-bottom: 5px;
        }
    }
`


export const Button = styled.button`
    width: 50px;
    height: 50px;
    padding: 15px;
    background-color: #5b5b5b;
    svg {
        width: 20px;
        height: 20px;
    }
    outline: none;
    border: 0;
    cursor: pointer;
    border-radius: 50%;
    &+button {
        margin-left: 24px;
    }
    svg {
        color: ${({theme}) => theme.text};
    }
`