import React,{useContext} from "react";
import * as S from './style'
import {ReactComponent as Close} from "assets/icons/close.svg";
import {ReactComponent as Menu} from "assets/icons/menu.svg";
import VideoContext from '../../context'
export default  (props) => {
    // State
    const {openDrawer, setOpenDrawer} = useContext(VideoContext);

    // Effects

    // Business

    // Render
    return (<S.Container>
        <div>{/*Header*/}</div>
        <button onClick={() => setOpenDrawer()}>{openDrawer ? <Close/> : <Menu/>}</button>
    </S.Container>)
}