import React, {useEffect, useState, useCallback} from "react";
import * as S from './style'
import {Header, VideoContainer, Drawer} from './parts'
import VideoContext from './context'
import {getSession, getChat, socket} from 'api/video'
import useOpenTok from 'react-use-opentok';
import moment from "moment"
import {ReactComponent as Digitalk}  from 'assets/icons/digitalk.svg'
import Welcome from "../welcome";

function Video() {
    // State
    const [openDrawer, setOpenDrawer] = useState(false)
    const [opentokProps, opentokMethods] = useOpenTok();
    const {connectionId, isSessionConnected, session, connections, streams, subscribers, publisher,} = opentokProps;
    const {initSessionAndConnect, disconnectSession, publish, unpublish, subscribe, unsubscribe, sendSignal,} = opentokMethods;
    const [video, setVideo] = useState({
        sessionId: "",
        token: "",
        videoSource: ['camera'],
        currentSubscribe: [],
        chat: [/*{
            name: 'Response',
            isSender: false,
            message: 'odasdsaadsiii'
        }*/],
        error: 'Essa sessão de vídeo irá começar em breve. \nAguarde!',
        load: true,
        audio: true,
        video: true,
        screen: false
    })


    // Effects
    useEffect(() => {
        const fetchData = async () => {
            const {sessionNexmo, token, api_key,sessionDigitalk} = await getSession(window.location.pathname.split('/')[1])
            const data = await getChat(window.location.pathname.split('/')[1])
            sessionNexmo && setVideo(prevState => ({
                ...prevState,
                sessionId: sessionNexmo,
                sessionDigitalk,
                apiKey: api_key,
                token,
                load: false,
                closed: false,
                chat: data,
                start: false
            }))
        }

        socket.emit('join', window.location.pathname.split('/')[1])
        socket.on('greeting', (data) => {
            console.log('recebendo greeting', data)
        })
        socket.on('send', ({sessionNexmo, token,api_key}) => {
            sessionNexmo && setVideo(prevState => ({...prevState,apiKey: api_key, sessionId: sessionNexmo, token, load: false}))
        })
        socket.on('message', (data) => {
            !openDrawer && setOpenDrawer(true)
            setVideo(prevState => ({
                ...prevState, chat: [...prevState.chat, {
                    ...data,
                    created_at: moment().format('DD/MM/YYYY HH:mm:ss'),
                    source: "operator"
                }]
            }))
        })

        fetchData();
    }, [])

    const handleSignal = useCallback(e => {
        console.log('handleSignal', e);
    }, []);

    const handleSessionDisconnected = useCallback(e => {
        console.log('handle session disconnected', e);
    }, []);

    useEffect(() => {
        if (!isSessionConnected) {
            return;
        }
        session.on('signal', handleSignal);
        session.once('sessionDisconnected', handleSessionDisconnected);
        return () => {
            session.off('signal', handleSignal);
        };
    }, [handleSignal, handleSessionDisconnected, isSessionConnected, session]);

    useEffect(() => {
        video.start && initSessionAndConnect({
            apiKey: video.apiKey,
            sessionId: video.sessionId,
            token: video.token,
        });
    }, [video.start]);

    //Businnes
    const streamGroups =
        streams &&
        streams.reduce((groups, stream) => {
            const {connection} = stream;
            groups[connection.connectionId] = groups[connection.connectionId] || [];
            groups[connection.connectionId].push(stream);
            return groups;
        }, {});

    // Render
    return <VideoContext.Provider value={{
        openDrawer,
        setOpenDrawer: () => setOpenDrawer(prevState => !prevState),
        opentokProps,
        opentokMethods,
        video,
        setVideo: (x) => setVideo(prevState => ({...prevState, ...x}))
    }}>
        <S.Container>
            {!video.start ? <Welcome/> : (video.closed ? <S.Closed>
                <p>Obrigado(a) por participar dessa vídeoconferência.</p>
                <div className='powered'>
                    <p>Powered by</p>
                    <Digitalk/>
                </div>
            </S.Closed>: <>
                <Header/>
                <S.Body>
                    <VideoContainer/>
                    <Drawer/>
                </S.Body>
            </>)}
        </S.Container>
    </VideoContext.Provider>
}

export default Video