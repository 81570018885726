import React, {useContext, useEffect, useRef, useState} from "react";
import * as S from './style'
import VideoContext from '../../context'
import ButtonAction from './buttonAction'
import classNames from 'classnames'

export default (props) => {
    // State
    const {opentokProps,opentokMethods, video, setVideo} = useContext(VideoContext);
    const PubView = useRef(null)
    const SubView = useRef(null)
    const [subs, setSubs] = useState([])
    const {connectionId, isSessionConnected, session, connections, streams, subscribers, publisher,} = opentokProps;
    const {initSessionAndConnect, disconnectSession, publish, unpublish, subscribe, unsubscribe, sendSignal,} = opentokMethods;
    // Effects
    function switchMedia(type){
        if (!publisher || !publisher.camera) {
            return
        }
        switch (type) {
            case 'audio':
                publisher.camera.publishAudio(video.audio)
                break
            case 'video':
                publisher.camera.publishVideo(video.video)
                break

            default:
                console.error('switchMedia not have type')
                break
        }
    }
    useEffect(() => {
        if (isSessionConnected) {
            publish({
                name: 'camera',
                element: 'camera'
            })
            //switchMedia('video')
        }
    }, [session, isSessionConnected])

    useEffect(() => {
        if (isSessionConnected) {
            switchMedia('video')
        }
    }, [video.video])
    useEffect(() => {
        if (isSessionConnected) {
            switchMedia('audio')
        }
    }, [video.audio])

    useEffect(() => {
        let curr = []
        streams.map(x => {
            if(!publisher || (publisher.camera && publisher.camera.streamId !== x.id)) {
                if(video.currentSubscribe.filter(y => y.id === x.id).length === 0){
                    setSubs(prevState => [...prevState,x.id])
                    if(x && x.videoType === "screen"){
                        setVideo({screen: true})
                        subscribe({stream: x, element: 'screen'});
                    }else{
                        subscribe({ stream: x, element: 'main' });
                    }
                }
                curr.push(x)
            }
        })
        video.currentSubscribe.map(x => {
            if(curr.filter(y => y.id === x.id).length === 0){
                if(x.videoType === "screen"){
                    setVideo({screen: false})
                }
                setSubs(prevState => [...prevState.filter(z => z !== x.id)])
                //unsubscribe({ stream: x });
            }
        })
        setVideo({currentSubscribe: curr})

    }, [streams])
    useEffect(() => {
        if(publisher && Object.keys(publisher).length > 0){
            !video.video && switchMedia('video')
            !video.audio && switchMedia('audio')
        }
    }, [publisher])
    // Render
    return (<S.Container ref={SubView} className={classNames({screen: video.screen})}>
        <S.Video>
            <S.Group>
                <S.Sub id='main'/>
                <S.Me id='camera' ref={PubView}/>
            </S.Group>
            <S.Screen id="screen"/>
        </S.Video>
        {/*{subs.map(x => <S.Sub id={x} key={x}/>)}*/}
        <ButtonAction/>
    </S.Container>)
}