import React, {useContext, useEffect, useRef, useState} from "react";
import {Container, Button} from "./style";
import {ReactComponent as Digitalk} from "../../assets/icons/digitalk.svg";
import VideoContext from '../video/context'
import {ReactComponent as Microphone} from "../../assets/icons/audio.svg";
import {ReactComponent as MicrophoneOff} from "../../assets/icons/audio-off.svg";
import {ReactComponent as Video} from "../../assets/icons/video.svg";
import {ReactComponent as VideoOff} from "../../assets/icons/video-off.svg";

const Welcome = () => {
    const {video, setVideo} = useContext(VideoContext)
    const [stream, setStream] = useState(null)
    const VideoView = useRef(null)
    useEffect(() => {
        if (navigator.getUserMedia) {
            navigator.getUserMedia({video: true}, handleVideo, videoError);
        }
        return () => stream && stream.getTracks().forEach(x => {
            x.stop()
        })
    }, [])

    const handleVideo = (e) => {
        setStream(e)
        VideoView.current.srcObject = e
        VideoView.current.play()
    }
    useEffect(() => {
        if (stream) {
            if (video.video) {
                if (navigator.getUserMedia) {
                    navigator.getUserMedia({video: true}, handleVideo, videoError);
                }
            } else {
                stream.getTracks().forEach(x => {
                    x.stop()
                })

                VideoView.current.srcObject = null
            }
        }
    }, [video.video])

    function videoError(e) {
        // do something
    }


    return <Container>
        <div className='preview'>
            <video ref={VideoView}/>
            <div>
                <Button onClick={() => setVideo({audio: !video.audio})}>{video.audio ? <Microphone/> :
                    <MicrophoneOff/>}</Button>
                <Button onClick={() => setVideo({video: !video.video})}>{video.video ? <Video/> : <VideoOff/>}</Button>
            </div>
        </div>


        <div className='welcome'>
            <h3>Seja bem-vindo(a) a Digiconference</h3>
            {video.load ? <p>Sua chamada começará em breve, por favor aguarde!</p> :
                <button onClick={() => setVideo({start: true})}>Iniciar</button>}
        </div>
        <div className='powered'>
            <p>Powered by</p>
            <Digitalk/>
        </div>
    </Container>
}

export default Welcome