import React, {useContext, useEffect, useRef, useState} from "react";
import {Container, Scrollbar, Form, InputFile, MessageItem, MessageContent, MessageHeader, MessageList} from './style'
import {ReactComponent as Smile} from "assets/icons/smile.svg";
import classNames from "classnames";
import {ReactComponent as Clip} from "assets/icons/clip.svg";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import {ReactComponent as Send} from "assets/icons/send.svg";
import VideoContext from "../../../context";
import {sendMessage} from "api/video";
import moment from "moment"
export default (props) => {
    const {video, setVideo} = useContext(VideoContext);
    const [form, setForm] = useState({text: '', file: []})
    const inputFile = useRef(null)
    const inputText = useRef(null)
    const messageList = useRef(null)
    const buttonForm = useRef(null)
    // State

    // Effects
    useEffect(() => {
        inputText.current.addEventListener("keydown", (e) => {
            if ((e.keyCode === 13 && !e.shiftKey) && (inputText.current.querySelector('textarea:first-child').innerHTML.trim() !== '' || form.file.length > 0)){
                e.preventDefault()
                buttonForm.current.click()
            }
        })}, [])
    useEffect(() => {
        (messageList && messageList.current.querySelector('li:last-child')) && messageList.current.querySelector('li:last-child').scrollIntoView()
    }, [video.chat])
    // Business
    const handleSubmit = (e) => {
        e && e.preventDefault()
        const fetchData = async (x) => {
            sendMessage(x)
        }
        const formData = new FormData()
        formData.set('session_digitalk', window.location.pathname.split('/')[1])
        formData.set('message', form.text)
        setVideo({
            chat: [...video.chat, {
                file: '',
                created_at: moment().format('DD/MM/YYYY HH:mm:ss'),
                source: 'customer',
                message: form.text
            }]
        })
        setForm({text: '', file: []})

        fetchData(formData)
    }
    // Render
    return (<Container>
        <Form onSubmit={(e) => handleSubmit(e)}>
            <Input cancel="input, textarea" classes={{root: 'input_text'}} ref={inputText} value={form.text} name='text' multiline={true} rowsMax={3} onChange={({target}) => setForm(prevState => ({...prevState,[target.name]: target.value}))}/>
            <InputFile  type='file' name='file' ref={inputFile} name="file" onChange={({target}) => setForm(prevState => ({...prevState, [target.name]: [...prevState.file,...target.files]}))} multiple/>
            {/*<Smile className={classNames({active: emojiPicker}, 'smile')}
                   onClick={this.handleEmojiPicker}/>*/}
            {/*<Clip onClick={() => inputFile.current.click()}/>*/}
            <Button type='submit' ref={buttonForm} disabled={(form.text.trim() === '' && form.file.length === 0)} color='primary' variant='contained'><Send/></Button>
        </Form>
        <Scrollbar>
            <MessageList ref={messageList}>
                {Array.isArray(video.chat) && video.chat.map((x,i) => <MessageItem className={classNames({client: x.source !== 'customer'})} key={i}>
                    <MessageHeader>{x.source !== 'customer' ? 'Atendente' : 'Eu'}<span>{x.created_at}</span></MessageHeader>
                    <MessageContent>{x.message}</MessageContent>
                </MessageItem>)}
            </MessageList>
        </Scrollbar>
    </Container>)
}