import styled from 'styled-components'

export const Container = styled.div`
    padding: 26px 0;
    background-color: ${({theme}) => theme.bg};
    display: flex;
    width: 100%;
    background-color: transparent;
    justify-content: center;
    position: absolute;
    align-items: center;
    bottom: 0;
    z-index: 2;
`
export const Button = styled.button`
    ${({isHangUp,theme}) => isHangUp ? `
        width: 60px;
        height: 60px;
        padding: 15px;
        background-color: #CB2A2A;
        svg {
            width: 30px;
            height: 30px;
        }
    ` : `
        width: 50px;
        height: 50px;
        padding: 15px;
        background-color: #5b5b5b;
        svg {
            width: 20px;
            height: 20px;
        }
    `}
    outline: none;
    border: 0;
    cursor: pointer;
    border-radius: 50%;
    &+button {
        margin-left: 24px;
    }
    svg {
        color: ${({theme}) => theme.text};
    }
`
/* @media (max-width: 768px) {
        background: red;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
        background: green;
    }

    @media (min-width: 1170px) {
        background: blue;
    }*/