import {useEffect, useState} from 'react';

export default function useTheme() {
    const [theme, setTheme] = useState('dark');
    useEffect(() => {
        const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        if(isDark) {
            setTheme('dark');
        }
    }, []);
    return [theme, setTheme];
}