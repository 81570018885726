import styled from 'styled-components'

export const Screen = styled.div`
    height: 70%;
    width: 100%;
    @media (min-width: 768px) {
        width: 70%;
        height: 100%;
    }
    z-index: 2;
    display: none;
`
export const Me = styled.div`
    width: 100%;
    height: 50%;
    @media (min-width: 768px) {
        width: 50%;
        height: 100%;
    }
`
export const Sub = styled.div`
    width: 100%;
    height: 50%;
    @media (min-width: 768px) {
        width: 50%;
        height: 100%;
    }
`
/* @media (max-width: 768px) {
        background: red;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
        background: green;
    }

    @media (min-width: 1170px) {
        background: blue;
    }*/


export const Video = styled.div`
    display: flex;
    transform: scale(1);
    width: 100%;
    height: calc(100% - 112px);
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
`
export const Group = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    @media (min-width: 768px) {
        width: 100%;
        height: 100%;
        flex-direction: row;
    }
`
export const Container = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #1B1B1B;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    .OT_mute {
        display: none;
    }
    &.screen {
        ${Me}, ${Sub} {
            width: 50%;
            height: 100%;
            @media (min-width: 768px) {
                width: 100%;
                height: 50%;
            }
        }
        ${Group} {
            flex-direction: row;
            height: 30%;
            @media (min-width: 768px) {
                height: 100%;
                width: 30%;
                flex-direction: column;
            }
        }
        ${Screen} {
            display: block;
            
        }
    }
`