import {createGlobalStyle} from "styled-components"
import ProximaLight from 'assets/fonts/ProximaNovaLight.otf'
import ProximaSemi from 'assets/fonts/ProximaNovaSemibold.otf'
import {Normalize} from 'styled-normalize'

export const GlobalStyle = createGlobalStyle`
    ${Normalize}
    @font-face {
        font-family: "ProximaNova";
        font-weight: 500;
        src: url('${ProximaLight}') format('opentype');
    }
    
    @font-face {
        font-family: "ProximaNova";
        font-weight: bold;
        src: url('${ProximaSemi}') format('opentype');
    }
    
    *,*:after,*:before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html, body, #root {
        width: 100%;
        height: 100%;
        font-size: 100%;
    }

    body {
        font: 16px 'ProximaNova', Georgia, sans-serif;
    }
`