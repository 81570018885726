import React, {useContext} from "react";
import * as S from './style'
import {ReactComponent as HangUp} from "assets/icons/hangUp.svg";
import {ReactComponent as MicrophoneOff} from "assets/icons/audio-off.svg";
import {ReactComponent as Microphone} from "assets/icons/audio.svg";
import {ReactComponent as VideoOff} from "assets/icons/video-off.svg";
import {ReactComponent as Video} from "assets/icons/video.svg";
import VideoContext from 'component/video/context'

export default () => {
    // State
    const {opentokProps, opentokMethods, video, setVideo} = useContext(VideoContext);
    const {disconnectSession} = opentokMethods;
    const {publisher} = opentokProps;

    const action = [
        {
            name: 'microphone',
            icon: video.audio ? <Microphone/> : <MicrophoneOff/>,
            click: () => (publisher && publisher.camera) && setVideo({audio: !video.audio})
        },
        {
            name: 'hangUp',
            icon: <HangUp/>,
            hangUp: true,
            click: () => {
                setVideo({closed: true})
                disconnectSession()
            }
        },
        {
            name: 'video',
            icon: video.video ? <Video/> : <VideoOff/>,
            click: () => (publisher && publisher.camera) && setVideo({video: !video.video})
        }
    ]
    // Effects

    // Business

    // Render
    return <S.Container>
        {action.map(b => <S.Button key={b.name} onClick={() => b.click()} isHangUp={b.hangUp}>{b.icon}</S.Button>)}
    </S.Container>
}